
import { defineComponent } from "vue";
import helpers from "@/helpers/global";
import { ElMessageBox } from "element-plus";
import CustomSelect from "@/components/Input/SelectBox.vue";
import Pagination from "@/components/Data/Pagination.vue";
import Loading from "../Basic/Loading.vue";

interface DataTable {
  id: string;
  candidateName: string;
  status: string;
  joined: string;
  rating: number;
  score: number;
  email: number;
  // Old
  routeId: string;
}

interface DropdownOption {
  key: string;
  label: string;
}

export default defineComponent({
  name: "MyRespondentsTable",
  components: {
    CustomSelect,
    Pagination,
    Loading,
  },
  computed: {
    dataFiltered() {
      const fillter = (this as any).fillterValue || "";
      // const data = JSON.parse(JSON.stringify(this.tableData));
      const data = this.tableData;
      const dataSorted: DataTable[] = (this as any).sortData(data, fillter);
      const dataSearched: DataTable[] = dataSorted.filter((item: any) => {
        return (
          item.candidateName
            .toLowerCase()
            .includes(this.searchInput.toLowerCase()) ||
          item.email
            .toString()
            .toLowerCase()
            .includes(this.searchInput.toLowerCase())
        );
      });
      const dataFilteredStatus: DataTable[] = dataSearched.filter(
        (item: any) => {
          return (
            item.status
              .toLowerCase()
              .includes(this.selectedStatus.key.toLowerCase()) ||
            this.selectedStatus.key == ""
          );
        }
      );
      const dataFilteredAssessment: DataTable[] = dataFilteredStatus.filter(
        (item: any) => {
          return (
            item.id
              .split("-")[0]
              .toLowerCase()
              .includes(this.selectedAssessment.key.toLowerCase()) ||
            this.selectedAssessment.key == ""
          );
        }
      );
      return dataFilteredAssessment || [];
      // return dataSearched || [];
    },
    tableSliceIndex() {
      const dataSlice: DataTable[] = (this.dataFiltered as any).slice(
        (this as any).dataIndexShowing[0] - 1,
        (this as any).dataIndexShowing[1]
      );
      return dataSlice || [];
    },
    tableData() {
      let candidateData: DataTable[] =
        this.$store.getters["respondent/itemscandidate"];
      return candidateData;
    },
    loading(): boolean {
      return this.$store.getters["respondent/loading"];
    },
    assessmentOption() {
      const assssment = this.$store.getters["respondent/itemsAssessment"];
      const assessmentOption: DropdownOption = assssment.map((item: any) => {
        return {
          key: item.value,
          label: item.label,
        };
      });
      return assessmentOption || [{ key: "", label: "" }];
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
  },
  watch: {
    tableData() {
      this.resetPagination();
    },
    searchInput() {
      this.resetPagination();
    },
    fillterValue() {
      this.resetPagination();
    },
  },
  data() {
    return {
      fillterValue: "",
      searchInput: "",
      selectedRating: "",
      selectedAssessment: {
        label: "",
        key: "",
      },
      page: 1,
      dataIndexShowing: [1, 10],
      assessmentsOptional: [
        {
          label: "As_AAAA",
          key: "As_AAAA",
        },
        {
          label: "As_BBB",
          key: "As_BBB",
        },
      ],
      selectedStatus: {
        label: "",
        key: "",
      },
      statusOptional: [
        {
          key: "INVITED",
          label: "Invited",
        },
        {
          key: "TODO",
          label: "Started",
        },
        {
          key: "IN_PROGRESS",
          label: "Incompleted",
        },
        {
          key: "COMPLETED",
          label: "Completed",
        },
      ],
      selectRating: [
        {
          value: "1",
          label: "★",
        },
        {
          value: "2",
          label: "★★",
        },
        {
          value: "3",
          label: "★★★",
        },
        {
          value: "4",
          label: "★★★★",
        },
        {
          value: "5",
          label: "★★★★★",
        },
      ],
    };
  },
  methods: {
    onClickRating(item: any, event: any) {
      // don't neccessary to await
      event.stopPropagation();
      this.$store.commit("assessment/ignoreLoading", true);
      this.$store.dispatch("assessment/saveRating", item, { root: true });
      this.$store.commit("assessment/ignoreLoading", false);
    },
    statusColor(status: string) {
      if (status.toUpperCase() === "Completed".toUpperCase()) {
        return "green"; // #22BC66
      } else if (status.toUpperCase() === "IN_PROGRESS".toUpperCase()) {
        return "red"; // #5EB8F6
      } else if (status.toUpperCase() === "TODO".toUpperCase()) {
        return "blue"; // #FF7575
      } else if (status.toUpperCase() === "INVITED".toUpperCase()) {
        return "orage"; // #FFC200
      }
      return "#1B1C1E";
    },
    getFormattedDate(joined: any) {
      return helpers.dateFormat(joined);
    },
    resetPagination() {
      this.page = 1;
      this.dataIndexShowing = [1, 10];
    },
    handlePageChange(page: any) {
      this.page = page.currentPage;
      this.dataIndexShowing = page.dataIndex;
    },
    handleAssessmentInput(newValue: any) {
      this.resetPagination();
      this.selectedAssessment = newValue;
    },
    handleStatusInput(newValue: any) {
      this.resetPagination();
      this.selectedStatus = newValue;
    },
    filterActiveClass(value: string) {
      if (this.fillterValue == value) {
        return "active";
      }
      return "";
    },
    workflowStatus(status: string): string {
      let label = status;
      if (status == "INVITED") {
        label = "INVITED";
      } else if (status == "TODO") {
        label = "STARTED";
      } else if (status == "IN_PROGRESS") {
        label = "INCOMPLETE";
      } else if (status == "COMPLETED") {
        label = "COMPLETED";
      }
      return label;
    },
    assessmentsCandidate(row: any) {
      if (row.status != "COMPLETED") return;
      const u_id = row.id;
      const as_id = u_id.split("-")[0];
      this.$router.push({
        name: "AssessmentsCandidate",
        query: {
          as_id: as_id,
          u_id: btoa(encodeURIComponent(u_id)),
        },
      });
    },
    async handleCommand(command: string, row: any) {
      const routeId = row.routeId;
      switch (command) {
        case "edit":
          this.$router.replace(`/assessment/edit/${row.routeId}`);
          break;
        case "clone":
          await this.$store.dispatch(
            "assessmentTemplate/clone",
            {
              routeId: routeId,
            },
            { root: true }
          );
          this.loadTables();
          break;
        case "delete":
          ElMessageBox.confirm(
            (this as any).$t("popup.question.question_box_2.title"),
            (this as any).$t("popup.question.question_box_2.question"),
            {
              confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
              cancelButtonText: (this as any).$t("popup.optional.Cancel"),
              type: "warning",
            }
          )
            .then(async () => {
              await this.$store.dispatch("assessmentTemplate/softDelete", row, {
                root: true,
              });
              this.loadTables();
            })
            .catch((error) => {
              if (error == "cancel") return;
              console.error(error);
            });
      }
    },
    loadTables() {
      this.$store.dispatch(
        "assessmentTemplate/getList",
        { page: 1 },
        { root: true }
      );
    },
    sortData(data: any, fillter: string) {
      if (fillter == "candidates_ascending") {
        return data.sort((a: any, b: any) =>
          a.candidateName > b.candidateName
            ? 1
            : b.candidateName > a.candidateName
            ? -1
            : 0
        );
      } else if (fillter == "candidates_descending") {
        return data.sort((a: any, b: any) =>
          a.candidateName < b.candidateName
            ? 1
            : b.candidateName < a.candidateName
            ? -1
            : 0
        );
      } else if (fillter == "joined_ascending") {
        return data.sort((a: any, b: any) =>
          a.joined > b.joined ? 1 : b.joined > a.joined ? -1 : 0
        );
      } else if (fillter == "joined_descending") {
        return data.sort((a: any, b: any) =>
          a.joined < b.joined ? 1 : b.joined < a.joined ? -1 : 0
        );
      } else if (fillter == "score_ascending") {
        return data.sort((a: any, b: any) =>
          a.score > b.score ? 1 : b.score > a.score ? -1 : 0
        );
      } else if (fillter == "score_descending") {
        return data.sort((a: any, b: any) =>
          a.score < b.score ? 1 : b.score < a.score ? -1 : 0
        );
      } else if (fillter == "status_ascending") {
        return data.sort((a: any, b: any) =>
          a.status > b.status ? 1 : b.status > a.status ? -1 : 0
        );
      } else if (fillter == "status_descending") {
        return data.sort((a: any, b: any) =>
          a.status < b.status ? 1 : b.status < a.status ? -1 : 0
        );
      } else if (fillter == "your_rate_ascending") {
        return data.sort((a: any, b: any) =>
          a.rating > b.rating ? 1 : b.rating > a.rating ? -1 : 0
        );
      } else if (fillter == "your_rate_descending") {
        return data.sort((a: any, b: any) =>
          a.rating < b.rating ? 1 : b.rating < a.rating ? -1 : 0
        );
      } else {
        return data || [];
      }
    },
    reSendInviteCls(row: any) {
      return this.canReSendInvite(row) ? false : true;
    },
    clearResultsCls(row: any) {
      return this.canClearResults(row) ? false : true;
    },
    canClearResults(row: any) {
      // @fixed
      // if (row) return true;
      return row.status != "INVITED";
    },
    canReSendInvite(row: any) {
      return row.status != "COMPLETED";
    },
  },
});
